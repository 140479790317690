import LinkWithAnalytics from '#components/shared/ui/Analytics/LinkWithAnalytics';
import type { TypographyColor, TypographyVariant } from '#components/shared/ui/Typography';
import { CONTACT_URL } from 'CONFIG';
import React from 'react';

type contactSectionText = {
  termsOfUse: string;
  privacyPolicy: string;
  cookiePolicy: string;
  noticeAndObligations: string;
  optOut: string;
  contactUs: string;
  reviews: string;
  blog: string;
};

type Props = {
  classList: string;
  mobileClassList: string;
  text: contactSectionText;
};

const styles = {
  textDecoration: 'underline',
  letterSpacing: '-0.32px',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '18px',
};

const commonParams = {
  color: 'branded' as TypographyColor,
  variant: 'capture' as TypographyVariant,
  style: styles,
};

enum ACTIONS {
  TERMS = 'terms',
  PRIVACY = 'privacy',
  COOKIE = 'cookie',
  FCRA = 'fcra',
  OPT = 'opt',
  HELP = 'help',
  REVIEWS = 'reviews',
  BLOG = 'blog',
}

enum PATH_PAGE {
  TERMS = '/terms/',
  PRIVACY = '/privacy-policy/',
  COOKIE = '/privacy-policy#uses-cookies',
  FCRA = '/terms#fcra',
  OPT = '/opt-out-information/',
  REVIEWS = '/reviews/',
  BLOG = '/blog/',
}

const getEvent = (action: ACTIONS, category?: string) => ({ action, category });

const ContactLinks: React.FC<Props> = ({ classList, mobileClassList, text }) => {
  return (
    <div className={classList}>
      <LinkWithAnalytics
        href={PATH_PAGE.TERMS}
        {...commonParams}
        text={text.termsOfUse}
        event={getEvent(ACTIONS.TERMS)}
        className="contactLinks"
      />
      <LinkWithAnalytics
        {...commonParams}
        href={PATH_PAGE.PRIVACY}
        text={text.privacyPolicy}
        event={getEvent(ACTIONS.PRIVACY)}
      />
      <LinkWithAnalytics
        {...commonParams}
        text={text.cookiePolicy}
        event={getEvent(ACTIONS.COOKIE)}
        href={PATH_PAGE.COOKIE}
      />
      <LinkWithAnalytics
        {...commonParams}
        href={PATH_PAGE.FCRA}
        event={getEvent(ACTIONS.FCRA)}
        text={text.noticeAndObligations}
      />
      <LinkWithAnalytics
        {...commonParams}
        text={text.optOut}
        href={PATH_PAGE.OPT}
        event={getEvent(ACTIONS.OPT, 'Landing')}
      />
      <LinkWithAnalytics
        target="_blank"
        {...commonParams}
        href={CONTACT_URL}
        text={text.contactUs}
        className={mobileClassList}
        event={getEvent(ACTIONS.HELP, 'Landing')}
      />
      <LinkWithAnalytics
        color="branded"
        variant="capture"
        href={PATH_PAGE.REVIEWS}
        text={text.reviews}
        style={styles}
        className={mobileClassList}
        event={getEvent(ACTIONS.REVIEWS, 'Landing')}
      />
      <LinkWithAnalytics
        href={PATH_PAGE.BLOG}
        target="_blank"
        text={text.blog}
        {...commonParams}
        event={getEvent(ACTIONS.BLOG, 'Landing')}
      />
    </div>
  );
};

export default ContactLinks;
